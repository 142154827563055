
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIClickToUploadFiles from "@/views/resources/documentation/element-ui/form/upload/ClickToUploadFiles.vue";
import EUIUserAvatarUpload from "@/views/resources/documentation/element-ui/form/upload/UserAvatarUpload.vue";
import EUIPhotoWall from "@/views/resources/documentation/element-ui/form/upload/PhotoWall.vue";
import EUICustomFileThumbnail from "@/views/resources/documentation/element-ui/form/upload/CustomFileThumbnail.vue";
import EUIFileListWithThumbnail from "@/views/resources/documentation/element-ui/form/upload/FileListWithThumbnail.vue";
import EUIFileListControl from "@/views/resources/documentation/element-ui/form/upload/FileListControl.vue";
import EUIDragToUpload from "@/views/resources/documentation/element-ui/form/upload/DragToUpload.vue";
import EUIManualUpload from "@/views/resources/documentation/element-ui/form/upload/ManualUpload.vue";

export default defineComponent({
  name: "upload",
  components: {
    EUIClickToUploadFiles,
    EUIUserAvatarUpload,
    EUIPhotoWall,
    EUICustomFileThumbnail,
    EUIFileListWithThumbnail,
    EUIFileListControl,
    EUIDragToUpload,
    EUIManualUpload
  },
  setup() {
    setCurrentPageTitle("Upload");
  }
});
